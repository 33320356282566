<template>
    <div class="Wrap">
        <div class="head">
            <img class="headBg" src="../../assets/images/statistics/bg-heaer.png" alt="">
            <div class="nav">
                <div style="cursor: pointer" :class="activeNav == index ? 'activeNav' : ''" @click="changeNav(index)" v-for="(item,index) in navlist" :key="item.id">{{item.name}} <img :ref="index+'navImg'" src="../../assets/images/statistics/nav-line.png" alt=""></div>
            </div>
            <div class="title">黑龙江省综合交通运输规划计划管理平台</div>
            <div class="time">{{nowTime}}</div>
        </div>
        <div>
            <JHGL></JHGL>
        </div>
    </div>
</template>

<script>
    import JHGL from "./JHGL";
    export default {
        name: "JhIndex",
        components:{JHGL},
        data(){
            return{
                timer: "", //定义一个定时器
                nowTime: "", // 当前日期时间
                date: new Date(),
                activeNav: 0,
                navlist:[{name:'计划管理',id:0}]
            }
        },
        mounted(){
            this.getTime();
            this.$refs['0navImg'][0].src= require('../../assets/images/statistics/nav-line-active.png')
        },
        beforeDestroy() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        methods:{
            changeNav(index){
                this.activeNav = index
                console.log(this.$refs[index+'navImg'])
                this.navlist.map((item,subindex)=>{
                    console.log(subindex)
                    if (subindex === index){
                        this.$refs[subindex+'navImg'][0].src= require('../../assets/images/statistics/nav-line-active.png')
                    }else{
                        this.$refs[subindex+'navImg'][0].src= require('../../assets/images/statistics/nav-line.png')
                    }
                })
            },
            getTime() {
                this.timer = setInterval(() => {
                    let timeDate = new Date();
                    let year = timeDate.getFullYear();
                    let mounth = timeDate.getMonth() + 1;
                    let day = timeDate.getDate();
                    let hours = timeDate.getHours();
                    hours = hours >= 10 ? hours : "0" + hours;
                    let minutes = timeDate.getMinutes();
                    minutes = minutes >= 10 ? minutes : "0" + minutes;
                    let seconds = timeDate.getSeconds();
                    seconds = seconds >= 10 ? seconds : "0" + seconds;

                    this.nowTime = `${year}/${mounth}/${day} ${hours}:${minutes}:${seconds} `;
                }, 1000);
            },

        }
    }
</script>

<style scoped lang="less">
    .Wrap{
        width: 100%;
        height: 100vh;
        background: url("../../assets/images/statistics/bg.png");
        .head{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .headBg{
                width: 100%;
                position: absolute;
                z-index: 0;
            }
            .nav{
                z-index: 1;
                margin-left: 63px;
                font-size: 16px;
                font-family: AliHYAiHei;
                color: #9BB9E0;
                display: flex;
                align-items: center;
                img{
                    margin: 0 29px 0 16px;
                    width: 19px;
                    height: 29px;
                }
                .activeNav{
                    color: #00FFFF;
                }
            }
            .title{
                z-index: 1;
                height: 61px;
                line-height: 40px;
                margin-left: -230px;
                font-size: 24px;
                font-family: AliHYAiHei;
                font-weight: 500;
                color: #FFFFFF;
                /*text-shadow: 0px 3px 1px #011B35;*/
                background: linear-gradient(0deg, rgba(81,192,243,0.8300) 0%, rgba(255,255,255,0.8300) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .time{
                width: 220px;
                z-index: 1;
                font-size: 16px;
                font-family:AliHYAiHei;
                color: #FFFFFF;
                text-shadow: 0px 6px 5px rgba(0,252,255,0.1800);
                background: linear-gradient(0deg, #1EAFF2 0%, #82E0FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
</style>
